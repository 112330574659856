<div class="container-actividad">

  <div class="ui warning message" *ngIf='(!data || data.length==0)'>
    <i class="close icon"></i>
    <div class="header">
      No se encontraron registros
    </div>
  </div>
  <table class="ui single line table" *ngIf='(data && data.length>0)'>
    <thead>
      <tr>
        <th class="th-item">Item </th>
        <th class="center aligned">Valor</th>
        <th class="center aligned no-pdf-cell">Foto</th>
        <th class="center aligned no-pdf-cell">Observaciones</th>
        <th class="center aligned no-pdf-cell">Comentarios</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of data">
        <td [attr.colspan]="(item.subtabla) ? '2': '1'" style="max-width: 400px !important;white-space: pre-wrap;"
          class="th-item">
          <span *ngIf="!item.values || item.values && item.values.length == 0">
            {{item.name}}
          </span>

          <div [style.margin]="'3px 21px 3px 15px'" *ngIf="item.values && item.values.length > 0">
            <div class="ui celled list">
              <div class="item" *ngFor="let v of item.values">
                <span>{{v.name}}</span>
                <span [style.float]="'right'" [ngClass]="(v.red)?'color-red':''" class="negrita">{{ v.value}} </span>
              </div>
            </div>
          </div>
        </td>
        <td style="width: 100px !important;white-space: pre-wrap;" class="center aligned" *ngIf=" !item.subtabla">
          <p *ngIf='item.respuesta!=="multiple"' [ngClass]="(item.red)?'color-red':'negrita'">
            {{item.respuesta}}
          </p>

        </td>
        <td style="width: 100px !important;white-space: pre-wrap;" class="no-pdf-cel">
          <img *ngIf="item.fotos && item.fotos.length > 0" class="pointer"
            (click)="showFoto(item.fotos[0].imagenes, item)" [src]="getUrl(item)" [style.display]="'flex'"
            [style.margin]="'3px'" alt="" width="70" (error)="handleImageError($event)">
        </td>
        <td style="width: 100px !important;white-space: pre-wrap;" class="center aligned no-pdf-cell">
          <i *ngIf="item.observaciones && item.observaciones[0]" class="search icon pointer font-blue"
            (click)="showObservaciones(item.observaciones,item)"></i>
        </td>
        <td style="width: 100px !important;white-space: pre-wrap;" class="center aligned no-pdf-cell">
          <div class="icon-actions">
            <i class="pencil alternate icon font-gray pointer" style="margin-right: 15px;"
              (click)="newComentario(item,item.comentarios)"></i>
          </div>
          <div class="icon-actions">
            <i *ngIf="item.comentarios.length > 0" class="comment alternate icon pointer font-blue"
              style="margin-left: 5px;" (click)="verComentario(item,item.comentarios)"></i>
          </div>
        </td>
      </tr>

    </tbody>
  </table>


</div>