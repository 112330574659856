import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiServerService } from 'src/app/core/services/api/api-server.service';
import { AsignacionesService } from 'src/app/core/services/asignaciones/asignaciones.service';
import { UsuarioService } from 'src/app/core/services/usuario/usuario.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-asignar',
  templateUrl: './asignar.component.html',
  styleUrls: ['./asignar.component.scss']
})
export class AsignarComponent implements OnInit {
  itemsSelecteds: any
  users: any = []
  userSelected = null
  asigned = false;
  esEjecutor = this.usuarioServices.getRoles().includes("Ejecutor")
  constructor(
    private usuarioServices: UsuarioService,
    private dialogRef: MatDialogRef<AsignarComponent>, // Añadir esta línea

    private asignacionService: AsignacionesService, private snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) public data: { itemsSelecteds: string }, private service: ApiServerService) {
    this.itemsSelecteds = data.itemsSelecteds;

    this.asignacionService.users().subscribe(res => {
      this.users = []
      res.data.forEach(d => {
        this.users.push(d.username)
      })
    })
  }

  ngOnInit(): void {
    if (this.esEjecutor) this.userSelected = this.usuarioServices.getUsername().toLowerCase();
  }
  asignar() {
    if (this.userSelected != null) {
      let ids: any[] = []
      this.itemsSelecteds.forEach(d => {
        ids.push(d._id)
      })
      this.asignacionService.asignar(ids, this.userSelected, [])
        .subscribe(
          res => {

            // Cargar de nuevo las listas
            this.snackBar.open('Las asignaciones se guardaron correctamente', 'x', {
              duration: 3000,
              verticalPosition: 'top',
              horizontalPosition: 'center',
              panelClass: ['green-snackbar'],
            });
            this.dialogRef.close(); // Cerrar el modal después de la asignación exitosa

          },
          (err: HttpErrorResponse) => {
            console.log('err:', err);
            let errorMessage = 'No se pudo asignar';

            if (typeof err === 'string') {
              errorMessage = `Error: ${err}`;
            }

            this.snackBar.open(errorMessage, 'x', {
              duration: 3000,
              verticalPosition: 'top',
              horizontalPosition: 'center',
              panelClass: ['red-snackbar'],
            });
          }
        );
    } else {
      this.snackBar.open('Debe seleccionar una categoría', 'x', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
        panelClass: ['yellow-snackbar'],
      });
    }
  }


}
