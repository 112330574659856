import { CommonModule } from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { AngularMaterialModule } from 'src/app/shared/angular-material/angular-material.module';
import { SharedModule } from 'src/app/shared/components/shared.module';
import { PreguntasComponent } from './components/preguntas/preguntas.component';
import { DetalleComponent } from './pages/detalle/detalle.component';
import { TableResultsComponent } from './components/table-results/table-results.component';
import { ModalObservacionesComponent } from './components/modal-observaciones/modal-observaciones.component';
import { BrowserModule } from '@angular/platform-browser';
import { AsigancionesComponent } from './pages/asiganciones/asiganciones.component';
import { AsingacionesDetallesComponent } from './components/asingaciones-detalles/asingaciones-detalles.component';
import { AsignarComponent } from './components/asignar/asignar.component';
import { TablaAsignadasComponent } from './components/tabla-asignadas/tabla-asignadas.component';
import { TablaNoAsignadasComponent } from './components/tabla-no-asignadas/tabla-no-asignadas.component';



@NgModule({
  declarations:[  PreguntasComponent, DetalleComponent, TableResultsComponent, ModalObservacionesComponent, AsigancionesComponent, AsingacionesDetallesComponent, AsignarComponent, TablaAsignadasComponent, TablaNoAsignadasComponent],
  imports:[ BrowserModule,CommonModule, RouterModule,AngularMaterialModule,SharedModule],
  exports:[]
})
export class AdministracionModule { }
