import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { FiltroActividades } from 'src/app/shared/models/filtroActividades';
import { AsignacionesService } from 'src/app/core/services/asignaciones/asignaciones.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  spinner = true;
  listRegistros: any[] = [];
  page = 1;
  formDatos: FormGroup;
  total: number;
  totalResults: any[] = [];
  pages_total = 0;
  filtros = new FiltroActividades;
  filtrosCompletados = false;
  constructor(private form: FormBuilder,
    private router: Router,
    private asignacionesService: AsignacionesService,
    private _snackBar: MatSnackBar,) {

    $('#spinner-load').css("display", "block");

    let filtros = localStorage.getItem('filtroActividades')
    if (!filtros) {
      this.filtros = new FiltroActividades();
    } else {
      this.filtros = JSON.parse(filtros);

    }


    this.formDatos = this.form.group({
      numberOT: [this.filtros.numberOT],
      denomination: [this.filtros.denomination],
      dateFrom: [this.filtros.dateFrom],
      dateUntil: [this.filtros.dateUntil],
      emyr: [this.filtros.emyr],
      section: [this.filtros.section],
      frequency: [this.filtros.frequency],
      state: [this.filtros.state],

    });
    this.cargarLista()
  }



  ngOnInit(): void {

  }

  ngAfterViewInit() {


    $('.ui.dropdown').dropdown({
      "clearable": true
    });

    $('.ui.selection.dropdown').dropdown({
      "clearable": true
    });
  }

  cargarLista() {
    $('#spinner-load').css("display", "block");

    this.asignacionesService.getAllResults(1, 15, this.filtros).subscribe(res => {
      this.listRegistros = res.data;
      this.pages_total = res.data?.length / 15;
      this.otFilters = res.otList
      this.utFilters = res.denominationList
      this.typeFilters = res.emyrList
      this.stateFilters = res.stateUtList
      this.frequencyFilters = res.frequencyList
      this.spinner = false;
      $('#spinner-load').css("display", "none");
    },
      error => {
        this._snackBar.open('Ocurrio un error al cargar los datos', 'x', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: ['error-snackbar'],
        });
        $('#spinner-load').css("display", "none");

      })

  }
  otFilters: any = [];
  utFilters: any = [];
  frequencyFilters: any = [];
  stateFilters: any = [];
  typeFilters: any = [];
  sectionFilters: any = [];

  completarFiltros() {

    this.filtrosCompletados = true;

  }


  detalle(item) {
    console.log(item)
    if (!item.Status.OT) {
      this._snackBar.open('No se encontro el campo OT', 'x', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
        panelClass: ['yellow-snackbar'],
      });
      return;
    }
    if (!item.Status.CodigoEstacion) {
      this._snackBar.open('No se encontro el campo Codigo', 'x', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
        panelClass: ['yellow-snackbar'],
      });
      return;
    }
    this.router.navigate(["detalle/" + item.Protocol_Id + "/" + item.Status.OT.Observed + "/" + item.Status.CodigoEstacion.Observed]);
  }



  limpiar() {
    this.page = 1;
    this.filtros = null;
    this.formDatos.controls['numberOT'].setValue(null)
    this.formDatos.controls['denomination'].setValue(null)
    this.formDatos.controls['dateFrom'].setValue(null)
    this.formDatos.controls['dateUntil'].setValue(null)
    this.formDatos.controls['emyr'].setValue(null)
    this.formDatos.controls['section'].setValue(null)
    this.formDatos.controls['frequency'].setValue(null)
    this.formDatos.controls['state'].setValue(null)
    this.filtros = new FiltroActividades();
    localStorage.setItem('filtroActividades', JSON.stringify(this.filtros))
    $('.ui.dropdown').dropdown('restore defaults');

    this.cargarLista()
  }

  changePage(number_page: number) {
    this.page = number_page;
    this.cargarLista();
  }

  aplicar() {
    this.page = 1;
    this.filtros = new FiltroActividades();
    this.filtros.numberOT = this.formDatos.get('numberOT').value;
    this.filtros.denomination = this.formDatos.get('denomination').value;
    this.filtros.dateFrom = this.formDatos.get('dateFrom').value;
    this.filtros.dateUntil = this.formDatos.get('dateUntil').value;
    this.filtros.emyr = this.formDatos.get('emyr').value;
    this.filtros.section = this.formDatos.get('section').value;
    this.filtros.frequency = this.formDatos.get('frequency').value;
    this.filtros.state = this.formDatos.get('state').value;

    localStorage.setItem('filtroActividades', JSON.stringify(this.filtros))

    $('#spinner-load').css("display", "block");

    this.cargarLista()


  }

  changeOrder(value) {
    this.filtros.orderWhen = value;
    this.cargarLista();
  }


}
