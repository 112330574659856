<div class="ui grid">
    <div class="six  wide column ">
        <h3 class='title-style mt-3'>Mantenimiento de EMyR</h3>
        <h2 class='negrita ' style='margin-top: 0px!important;margin-left: 0px!important;'>
            Frecuencia:
            <span class='font-gray' style='margin-top: 0px!important;padding-left: 5px;'> {{frequency}}</span>
        </h2>
    </div>
    <div class="ten wide column right aligned  no-pdf"  >
       
        <button 
            class="ui right labeled icon button  primary no-pdf" (click)="descargarPDF()"
            style='vertical-align:inherit;margin-top:1.5em;' [disabled]="!puedeDescargar">
            <i class="download icon pointer"></i>
            DESCARGAR PDF
        </button>

        <button id='b3' class="ui right labeled icon button  primary no-pdf" id='copiarLink' (click)="copyURL()"
            style='vertical-align:inherit;margin-top:1.5em;'>
            <i class="clone outline pointer
                icon"></i>
            COPIAR LINK

        </button>
    </div>



</div>

<div class="ui divider no-pdf-flex"></div>