import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { FiltroActividades } from 'src/app/shared/models/filtroActividades';
import { FiltroAsignaciones } from 'src/app/shared/models/filtrosAsignaciones';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AsignacionesService {
  headers = new HttpHeaders({
    // 'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('tokenApi')}`
  });
  headersBack = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('token')}`
  });
  constructor(private http: HttpClient) { }
  filtrar(filtros: FiltroAsignaciones) {
    const params = new URLSearchParams();

    if (filtros.NPerPage) {
      params.append('nPerPage', filtros.NPerPage.toString());
    }
    if (filtros.pageNumber) {
      params.append('pageNumber', filtros.pageNumber.toString());
    }
    if (filtros.orderBy) {
      params.append('orderBy[When]', filtros.orderBy);
    }

    if (filtros.state && filtros.state.length > 0) {
      filtros.state.forEach(s => {
        params.append('state', s);
      });
    }
    if (filtros.username) {
      params.append('username', filtros.username.trim());
    }
    if (filtros.startDate) {
      let startDate = new Date(`${filtros.startDate}T00:00:00Z`);
      startDate.setUTCHours(startDate.getUTCHours() + 3);
      params.append('startDate', startDate.getTime().toString());

    }

    if (filtros.endDate) {
      let endDate = new Date(`${filtros.endDate}T00:00:00Z`);
      endDate.setUTCHours(endDate.getUTCHours() + 3);
      params.append('endDate', endDate.getTime().toString());

    }
    if (filtros.protocolName) {
      params.append('Protocolo', filtros.protocolName);
    }
    if (filtros.description) {
      params.append('description', filtros.description.trim());
    }
    if (filtros.assignedTo) {
      params.append('assignedTo', filtros.assignedTo);
    }
    if (filtros.ot) {
      params.append('OT', filtros.ot.trim());
    }
    if (filtros.frencuency) {
      params.append('Frecuencia', filtros.frencuency.trim());
    }
    /*if (filtros.sections) {
      params.append('Seccion', filtros.sections.trim());
    }*/
    if (filtros.sections && filtros.sections.length > 0) {
      filtros.sections.forEach(element => {
        params.append('Seccion', element.trim());
      });
    }
    if (filtros.sectionsCods && filtros.sectionsCods.length > 0) {
      filtros.sectionsCods.forEach(element => {
        params.append('CodigoSeccion', element.trim());
      });
    }
    params.append('token', localStorage.getItem('token'));

    return this.http.get(`${environment.apiUrl}/api/assignments/filter?${params.toString()} `).pipe(
      map((isAlive: any) => {
        return isAlive;
      })
    );
  }


  users() {
    const params = new URLSearchParams();
    params.append('token', localStorage.getItem('token'));

    return this.http.get(`${environment.apiUrl}/api/user?${params.toString()} `).pipe(
      map((isAlive: any) => {
        return isAlive;
      })
    );
  }

  asignar(assignments: any[], user?: string, userCategories?: any[]) {
    let datos: { assigments: any[]; user?: any; userCategories?: any[] } = {
      assigments: assignments
    };
    if (user) {
      datos.user = user;
    }
    if (userCategories) {
      datos.userCategories = userCategories;
    }

    return this.http.post(`${environment.apiUrl}/api/assignments/assignTo?token=${localStorage.getItem('token')}`, datos).pipe(
      map((isAlive: any) => {
        console.log(isAlive)

        return isAlive;
      }),
      catchError((error: HttpErrorResponse) => {
        //console.error('Error en asignar:',  error);
        let errorMessage = 'Error desconocido';
        if (error.error) {
          errorMessage = typeof error.error === 'string' ? error.error : (error.error.message || JSON.stringify(error.error));
        } else if (error.message) {
          errorMessage = error.message;
        }
        return throwError(() => new Error(errorMessage));
      })
    );
  }

  desasignar(assignment: string) {
    let datos = {
      assigment: `${assignment}`
    };

    return this.http.post(`${environment.apiUrl}/api/assignments/dismissAssigment?token=${localStorage.getItem('token')}`, datos).pipe(
      map((isAlive: any) => {
        return isAlive;
      })
    );
  }

  getAllResults(pageNumber: any, pageSize: any, filtros: FiltroActividades) {


    console.log("prams", filtros)
    return this.http.post(`${environment.urlBack}/allResults?pageNumber=${pageNumber}&pageSize=${pageSize}&token=${localStorage.getItem('token')} `, filtros, { headers: this.headers }).pipe(
      map((isAlive: any) => {
        return isAlive;
      })
    );
  }

}
