import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var $: any;
import { AsignacionesService } from 'src/app/core/services/asignaciones/asignaciones.service';
import { UsuarioService } from 'src/app/core/services/usuario/usuario.service';
import { FiltroAsignaciones } from 'src/app/shared/models/filtrosAsignaciones';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tabla-no-asignadas',
  templateUrl: './tabla-no-asignadas.component.html',
  styleUrls: ['./tabla-no-asignadas.component.scss']
})
export class TablaNoAsignadasComponent implements OnInit {
  filtros = new FiltroAsignaciones();
  notAssigned = [];
  itemsSelecteds: any[] = []
  activeFilter: string = '';
  @Output() changeCheck: EventEmitter<any[]> = new EventEmitter<any[]>();
  secciones: any
  rol: any;
  input: any = {}
  constructor(public snackBar: MatSnackBar,
    private usuarioService: UsuarioService,
    private asignacionService: AsignacionesService,
    public dialog: MatDialog,
  ) {
    this.filtros.state = ["notassigned"];
    this.secciones = this.usuarioService.getSections();
    this.filtros.protocolName = environment.protocolName;
    this.rol = this.usuarioService.getRoles()
    if (this.secciones[0] != '*' && this.rol.includes("Ejecutor")) {
      this.secciones.forEach(d => {
        this.input.sectionsCods = d;
        this.addFilter("sectionsCods")
      })
    }
  }

  ngOnInit(): void {
    this.changePageTable1(1);
  }
  pagesTotalTable1 = 2;
  changePageTable1(page: number) {
    this.filtros.pageNumber = page;

    this.cargarLista();
  }
  cargarLista() {


    this.asignacionService.filtrar(this.filtros).subscribe(res => {
      this.notAssigned = res.data.map(item => {
        item.When = new Date(item.When)
        return item;
      });
      this.filtros.totalItems = res.count;
      this.filtros.pageTotal = res.count / this.filtros.NPerPage;
      $('#spinner-load').css("display", "none");
    })
  }

  toggleSelection(event: any, itemId: number) {
    if (event.target.checked) {
      // Si se selecciona el checkbox, agregamos el ID a itemsSelecteds
      this.itemsSelecteds.push(itemId);
    } else {
      // Si se deselecciona el checkbox, removemos el ID de itemsSelecteds
      const index = this.itemsSelecteds.indexOf(itemId);
      if (index !== -1) {
        this.itemsSelecteds.splice(index, 1);
      }
    }
    this.changeCheck.emit(this.itemsSelecteds)

  }
  isChecked(itemId: number): boolean {
    return this.itemsSelecteds.includes(itemId);
  }

  toggleFilter(filterName: string) {
    this.activeFilter = this.activeFilter === filterName ? '' : filterName;
  }
  puedeCambiar(item) {
    if (!this.secciones[0].includes('*')) {
      if (this.rol == 'Ejecutor' || this.rol == 'Jefe de Sección' || this.rol == "Analista de Mantenimiento") {

        if (this.secciones.includes(item.Status.CodigoSeccion?.Observed)) {
          return true;
        }
      }else{
        return false;
      }
    } else {
      return true;
    }
  }
  checkAll(event: any) {
    if (event.target.checked) {
      if (this.secciones[0] == '*' || (this.rol != 'Ejecutor' && this.rol != 'Jefe de Sección' && this.rol != "Analista de Mantenimiento")) {
        this.notAssigned.forEach(item => {
          this.itemsSelecteds.push(item._id);
        });
      } else {
        this.notAssigned.filter(d => this.secciones.includes(d.Status.Seccion?.Observed)).forEach(item => {
          this.itemsSelecteds.push(item._id);
        });
      }
    } else {
      this.itemsSelecteds = []
    }
    this.changeCheck.emit(this.itemsSelecteds)
  }
  limpiar(tipo) {
    if (tipo == "date") {
      this.filtros['startDate'] = null;
      this.filtros['endDate'] = null;
    } else {
      this.filtros[tipo] = null;
    }
    this.cargarLista();
  }

  addFilter(tipo) {
    if (this.filtros[tipo]) {
      this.filtros[tipo].push(this.input[tipo])
    } else {
      this.filtros[tipo] = [this.input[tipo]]
    }
    this.input[tipo] = '';
    this.cargarLista();
  }
  removeFilter(tipo, value) {
    this.filtros[tipo] = this.filtros[tipo].filter(d => d != value);
    this.cargarLista();

  }
}
