<div class="ui container">
    <div class="ui card" [style.width]="'100%'">
        <div class="ui top attached tabular menu">
            <div class="container-tab">
                <div [ngClass]="(tab==1) ? 'item active' : 'item'" (click)="changeTab(1)">SIN ASIGNAR</div>
                <div [ngClass]="(tab==2) ? 'item active' : 'item'" (click)="changeTab(2)" >ASIGNADO</div>
            </div>
            <div class="container-btn">
                <button *ngIf="tab==1" (click)="asignar()" [disabled]="itemsSelecteds.length == 0"
                    class="ui button  primary">
                    ASIGNAR
                </button>
            </div>
        </div>
        <div *ngIf="tab==1" class="ui bottom attached active tab segment">
            <app-tabla-no-asignadas   (changeCheck)='selecteds($event)'></app-tabla-no-asignadas>
        </div>
        <div *ngIf="tab==2" class="ui bottom attached active tab segment">
          <app-tabla-asignadas></app-tabla-asignadas>
        </div>
    </div>
</div>