export const environment = {
    production: true,
    apiUrl: 'https://tgnv12.smartworkar.com:8888',
    frontUrl: '',
    urlAssets: 'http://localhost:4200',
    //urlBack: 'http://localhost:8083',
    urlBack: 'https://tgn-emyr-back.candoit.com.ar',
    user: {
        username:  "api_client@smartworkar.com",
        password:  "$nSxb$PW7E"
    },
    userApi: {
        username: "a",
        password: "a"
    },
        protocolName:"EMyR_Mensual"

};
