import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GaleriaService } from 'src/app/core/galeria/galeria.service';
import { GalleryImage } from '../../models/galeryImage';

@Component({
  selector: 'app-gallery-modal',
  templateUrl: './gallery-modal.component.html',
  styleUrls: ['./gallery-modal.component.scss']
})
export class GalleryModalComponent implements OnInit, OnDestroy {

  public image: GalleryImage;
  @Input() gallery: GalleryImage[] = [];
  titulo: string;
  @Input() position: number;
  @Input() descripcion: string;
  spinner = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { titulo: string, descripcion: string; gallery: GalleryImage[]; position: number },
    private ref: ChangeDetectorRef,
    private readonly galleryService: GaleriaService
  ) {
    if (data) {
      this.titulo = data.titulo;
      this.gallery = data.gallery;
      this.position = data.position;
      this.descripcion = data.descripcion;
    }
  }

  ngOnInit(): void {
    this.getImageSelected();

  }

  getImageSelected(): void {
    this.image = this.gallery.find(d => d.position == this.position);

  }
  changeImg(move: number): void {
    this.position = this.position + move;
     if(this.position<0){
      this.position= this.gallery.length -1;
     }
     if(this.position >= this.gallery.length){
      this.position=0;
     }

   
  }

  ngOnDestroy(): void {
    this.gallery = [];
    this.galleryService.createGallery(this.gallery);
    this.image = null;
  }

}
