import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ApiServerService } from 'src/app/core/services/api/api-server.service';
import { Service } from 'src/app/core/services/misiones/misiones.service';
import { UsuarioService } from 'src/app/core/services/usuario/usuario.service';
import { GalleryModalComponent } from 'src/app/shared/components/gallery-modal/gallery-modal.component';
import { ModelComentarioComponent } from 'src/app/shared/components/model-comentario/model-comentario.component';
import { GalleryImage } from 'src/app/shared/models/galeryImage';
import { environment } from 'src/environments/environment';
import { ModalObservacionesComponent } from '../modal-observaciones/modal-observaciones.component';
import { Descripcion } from 'src/app/shared/models/descripciones';

@Component({
  selector: 'app-table-results',
  templateUrl: './table-results.component.html',
  styleUrls: ['./table-results.component.scss']
})
export class TableResultsComponent implements OnInit {

  @Input() data;
  @Input() nro;
  @Input() missionId;

  token: any;
  posicionAux: number;
  fotosProcesadas: any[] = [];
  gallery: GalleryImage[] = [];
  constructor(public dialog: MatDialog, private service: Service, private apiservice: ApiServerService, private rutaActiva: ActivatedRoute, private usuarioServices: UsuarioService) {
    this.token = this.usuarioServices.getToken();
    
  }
  ngOnInit(): void {
    if (this.data) {
      this.data.forEach(item => {
        item.comentarios=[]
        this.apiservice.getComentario(item.idComments).subscribe(res=>{
          item.comentarios =  (res.commentsDTOs) ? res.commentsDTOs :[];
          item.fotos = item.fotos
          item.observaciones = item.observaciones
        })
      
      });
    }

  }

  buscarObservaciones(item: any) {
    let observaciones: any = {}
    if (this.data.observaciones) {
      let findObservacones = this.data.observaciones.filter(d => d.Instruction_Id == item.Instruction_Id || Array.isArray(d.SubjectPart) && d.SubjectPart.includes(item.Instruction_Id))
      if (findObservacones.length > 0) {
        observaciones.lista = []
        findObservacones.forEach(o => {
          observaciones.lista = []
          observaciones.id = o.Mission_Id;
          observaciones.lista.push(o.Status.Observacion)
        })
        return observaciones;
      } else {
        return null;
      }
    }

  }



  showFoto(fotos, titulo) {
    this.gallery = [];
    var i = 0;
    fotos.forEach(f => {
      const image = new GalleryImage();
      //  image.src = `${environment.apiUrl}/api/instance/instancia-de-prueba/mission/Mission_${data.id}/file/${f}?token=${this.token}`;
      image.src = `${environment.apiUrl}/api/instance/instancia-de-prueba/mission/Mission_${titulo.missionId}/file/${f}?token=${this.token}`;
      image.position = (this.posicionAux) ? this.posicionAux : i;
      this.gallery.push(image);
      i = i + 1;
    })

    this.dialog.open(GalleryModalComponent, { panelClass: 'custom-dialog-container', data: { titulo: titulo.name, descripcion: '', gallery: this.gallery, position: 0 } });
  }

  showObservaciones(data, titulo) {
    let info = []
    data.forEach(item => {
      let observacion: any = {}
      observacion.gallery = [];
      let fotos = item.imagenes;
      observacion.text = item.text;
      var i = 0;
      fotos.forEach(f => {
        const image = new GalleryImage();
        image.alt = f;
        image.src = `${environment.apiUrl}/api/instance/instancia-de-prueba/mission/Mission_${titulo.missionId}/file/${f}?token=${this.token}`;
        image.position = (this.posicionAux) ? this.posicionAux : i;
       
        observacion.gallery.push(image);
       
        i = i + 1;
      })
      info.push(observacion)

    })
   
    this.dialog.open(ModalObservacionesComponent, { panelClass: 'custom-dialog-container', data: { titulo: titulo.name, info: info, descripcion: data[0]?.text } });
  }

  verComentario(item, comentarios) {
 
    this.dialog.open(ModelComentarioComponent, { panelClass: 'custom-dialog-container', data: { nro: this.nro, _id: item.idComments, comentarios: comentarios, type: "ver" } });
  }


  newComentario(item, comentarios) {
    let id = item.idComments;
    const dialogRef = this.dialog.open(ModelComentarioComponent, { panelClass: 'custom-dialog-container', data: { _id: id, nro: this.nro, comentarios: comentarios, type: "crear" } });
    dialogRef.afterClosed().subscribe(() => {
      this.apiservice.getComentario(id).subscribe(res => {
        let comentarios = res.commentsDTOs;
        item.comments = (comentarios) ? comentarios : [];
      })
    });
  }

  getUrl(item) {
   if(item.fotos && item.fotos[0].imagenes && item.fotos[0].imagenes.length>0){
    return `${environment.apiUrl}/api/instance/instancia-de-prueba/mission/Mission_${item.missionId}/file/${item.fotos[0].imagenes}?token=${this.token}`;

   }
  }
  handleImageError(event: Event): void {
    const element = event.target as HTMLImageElement;
    element.src = '/assets/img/unnamed.jpg'; 
  }
  getIdComentario(item) {
   /*  let instruccion = item['Instruction_Dsc']
    return item['Mission_Id'] + "-" + item['Activity_Id'] +  '-' + instruccion.replace(/[^\w\s]/g, ''); */
    ;
  }
}
