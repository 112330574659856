import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServerService } from 'src/app/core/services/api/api-server.service';
import { Service } from 'src/app/core/services/misiones/misiones.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { DataService } from 'src/app/data.service';
import { AuthService } from 'src/app/core/authentication/auth.service';
declare var $: any;

@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.scss']
})
export class DetalleComponent implements OnInit {

  url = environment.urlBack;
  actividades: any[] = []
  frequency = null;
  token = null;
  ot = null;
  code = null;
  performer = null;
  section = null;
  when = null;
  ut = null;
  constructor(
    private router: Router,
    private rutaActiva: ActivatedRoute,
    private service: Service,
    private serviceApi: ApiServerService,
    private authService: AuthService,
    public dialog: MatDialog,

  ) {
    this.initializeData();
    /*this.authService.Token().subscribe(res=>{
      console.log(res)
    })*/
  }
  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    $('.ui.accordion').accordion();
  }
  goHome() {
    this.router.navigate(['/']);
  }


  initializeData(): void {

    $("#spinner-load").css("display", "block");
    let protocol = this.rutaActiva.snapshot.params.protocol;
    let ot = this.rutaActiva.snapshot.params.ot;
    let code = this.rutaActiva.snapshot.params.code;
    this.service.getCargaInicial(protocol, ot, code).subscribe(res => {
      console.log("res", res)
      this.ot = res.Status.OT?.Observed;
      this.code = res.Status.CodigoEstacion?.Observed;
      this.frequency = res.Status.Frecuencia?.Observed;
      this.section = res.Status.Seccion?.Observed;
      this.performer = res.Who[0].username;
      this.ut = res.Status.Description.Observed;

      this.serviceApi.getDetail([res.Mission_Id]).subscribe(d=>{
        console.log("D", d)
        this.actividades = d

        $('#spinner-load').css("display", "none");

      })
      
      /* this.serviceApi.getComentarios(ot).subscribe(comentarios => {
        this.service.getMissionId(protocol, res.Mission_Id).subscribe(results => {
          console.log("res, ", results)
          //comentr tod
        

          $('#spinner-load').css("display", "none");
        })
      }) */


    })


  }



  groupByInstructionId(data: { Instruction_Id: number }[]) {
    const groupedData = data.reduce((items, item) => {
      const key = item.Instruction_Id;

      if (!items[key]) {
        items[key] = [];
      }

      items[key].push(item);

      return items;
    }, {});

    const formattedResult = Object.entries(groupedData).map(([key, items]) => ({
      key: key,
      items,
    }));

    return formattedResult;
  }
  
  



  groupByActividad(data) {
    const key = "Activity_Dsc";
    return Object.entries(data.reduce((acc, cur) => {
      const actividad = cur[key];
      const sec = cur["Activity_Seq"];

      if (!acc[actividad]) {
        acc[actividad] = {
          key: actividad,
          sec: sec,
          results: [],
        };
      }

      const curCopy = { ...cur };
      acc[actividad].results.push(curCopy);

      return acc;
    }, {})).map(([key, value]) => value);
  }

  filtrarUltimas(preguntas: any) {
    const map = new Map<string, any>();
    preguntas.forEach(obj => {
      const compositeKey = `${obj.Instruction_Id}_${obj.Evidence_Type}`;
      const existingObj = map.get(compositeKey);
      if (!existingObj || obj.When > existingObj.When) {
        map.set(compositeKey, obj);
      }
    });
    const result = Array.from(map.values());
    return result;
  }

  ordenarPreguntas(arr: any) {
    return arr.sort((x, y) => x.Instruction_Seq - y.Instruction_Seq);
  }
  formatearNumero(respuesta) {
    if (!isNaN(respuesta)) {
      if (respuesta % 1 !== 0) {
        respuesta = Number(respuesta).toFixed(2);
      }
    }
    return respuesta;
  }

  activePanel(i) {

    if (document.getElementById("panel" + i).classList.contains("active")) {
      document.getElementById("panel" + i).classList.remove("active");
      document.getElementById("content" + i).classList.remove("active");
    } else {
      document.getElementById("panel" + i).classList.add("active");
      document.getElementById("content" + i).classList.add("active");
    }

  }
}


