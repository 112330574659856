import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../authentication/auth.service';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { UsuarioService } from '../services/usuario/usuario.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {


  constructor(private authService: AuthService, private usuarioServices: UsuarioService) {

  }



  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    return next.handle(request).pipe(

      catchError((error: HttpErrorResponse) => {

        /*if (error.status === 403 || error.status === 401) {
          localStorage.clear()
          document.location.reload()
        }*/


        if (error.status === 404) {
          //this.redirectService.toNotFound(errorMessage);
        } else {
          // this.redirectService.toError(errorMessage);
        }

        return throwError(error);
      }));
  }


}
