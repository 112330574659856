import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AsignarComponent } from '../../components/asignar/asignar.component';
import { TablaNoAsignadasComponent } from '../../components/tabla-no-asignadas/tabla-no-asignadas.component';
import { TablaAsignadasComponent } from '../../components/tabla-asignadas/tabla-asignadas.component';
import { UsuarioService } from 'src/app/core/services/usuario/usuario.service';
declare var $: any;

@Component({
  selector: 'app-asiganciones',
  templateUrl: './asiganciones.component.html',
  styleUrls: ['./asiganciones.component.scss']
})
export class AsigancionesComponent implements OnInit {
  tab = 1;
  itemsSelecteds: any[] = []
  @ViewChild(TablaNoAsignadasComponent) tablaNoAsignadasComponent: TablaNoAsignadasComponent;
  @ViewChild(TablaAsignadasComponent) tablaAsignadasComponent: TablaAsignadasComponent;

  constructor(public snackBar: MatSnackBar,
    public dialog: MatDialog, public usuarioService: UsuarioService
  ) {

  }

  ngOnInit(): void {
    console.log("ROL", this.usuarioService.getRoles())
  }


  asignar() {
    const dialogRef = this.dialog.open(AsignarComponent, { panelClass: 'custom-dialog-container', data: { itemsSelecteds: this.itemsSelecteds } });
    dialogRef.afterClosed().subscribe(() => {
       this.tablaNoAsignadasComponent.cargarLista();
    });
  }


  changeTab(n) {
    this.tab = n;
  }
  selecteds(event){
    this.itemsSelecteds=event;
  }
}
