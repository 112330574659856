import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-asingaciones-detalles',
  templateUrl: './asingaciones-detalles.component.html',
  styleUrls: ['./asingaciones-detalles.component.scss']
})
export class AsingacionesDetallesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
