<div class="ui grid secondary menu" id='header-app'>
  <div class=" four  wide column ">
    <a routerLink="/" class="" style="margin-left:10px;">
      <img class="ui centered  logo image" src="./assets/img/logo-tgn.png" alt="logo">
    </a>
  </div>
  <div class=" height wide column ">
    <h2 class='title-style'>Estaciones de medición y regulación</h2>

  </div>
  <div class=" four  wide column ">
    <div *ngIf='isLogged()' class="column col-actions" >
      <div *ngIf="mostrar()" class="ui dropdown manu-dropdown" style="font-weight:bold; padding:17.5px 17.5px 17.5px 1px;">
        <i class="cogs icon" style="margin:17px 0px 0px 17px;"></i>
        Opciones
        <i class="dropdown icon"></i>
        <div class="menu">
          <div role="link" class="item" (click)='asignaciones()' >
            <span class="">Asignaciones</span>
          </div>
        </div>
      </div>
      <div *ngIf='isLogged()' role="listbox" class="ui  dropdown manu-dropdown" tabindex="0"
        style="color:rgb(32, 156, 193); font-weight:bold; padding:17.5px 17.5px 17.5px 1px;" aria-expanded="true">
        <i aria-hidden="true" class="user icon" style="color:rgb(32, 156, 193); margin:17px 0px 0px 17px;"></i>
        <span class="username">{{user.slice(0,8)}} <span sytle="margin-bottom:0em!important!;" *ngIf="user.length>7">...</span></span>
        
        <i aria-hidden="true" class="dropdown icon"></i>
        <div class="menu  ">
          <div role="option" class="item">
            <span class="" (click)='logout()'>Log out</span>
          </div>
        </div>
      </div>
      <div *ngIf='!isLogged()' role="listbox" class="ui  dropdown manu-dropdown" tabindex="0"
        style="color:rgb(32, 156, 193); font-weight:bold; padding:17.5px 17.5px 17.5px 1px;" aria-expanded="true">
        <i aria-hidden="true" class="user icon" style="color:rgb(32, 156, 193); margin:17px 0px 0px 17px;"></i>
        {{user}}
        <i aria-hidden="true" class="dropdown icon"></i>
        <div class="menu  ">
          <div role="option" class="item">
            <span class="" (click)='login()'>Log in</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  

</div>