import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/components/shared.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlArg } from './shared/models/MatPaginatorIntlArg';
import { registerLocaleData } from '@angular/common';
import localeAr from '@angular/common/locales/es-AR';
import { AngularMaterialModule } from './shared/angular-material/angular-material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
registerLocaleData(localeAr, 'es-AR');
import { CommonModule } from '@angular/common';


@NgModule({
  declarations: [AppComponent],
  imports:[ CommonModule,BrowserModule, HttpClientModule, FormsModule, AngularMaterialModule, CoreModule, SharedModule, AppRoutingModule, BrowserAnimationsModule],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlArg },
    CookieService,
  { provide: MAT_DATE_LOCALE, useValue: 'es-AR' },
  { provide: LOCALE_ID, useValue: 'es-AR' }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
