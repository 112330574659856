import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsuarioService } from 'src/app/core/services/usuario/usuario.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-header-component',
  templateUrl: './header-component.component.html',
  styleUrls: ['./header-component.component.scss']
})
export class HeaderComponentComponent implements OnInit {
  @Input() frequency;
  @Input() type;

  html: string;
  @Output() accionDescargar: EventEmitter<boolean> = new EventEmitter<boolean>();
  puedeDescargar: boolean = true
  constructor(public snackBar: MatSnackBar, private router: Router, private routerActivated: ActivatedRoute, private usuarioServices: UsuarioService) {
  }

  ngOnInit(): void {
    this.puedeDescargar = this.usuarioServices.getState()
  }

  copyURL() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = document.location.href;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.mensaje('Link copiado!', '')
  }

  descargarPDF() {
    this.accionDescargar.emit(true);
    $('.dropdown.icon').hide();
    $('.title').addClass('active')
    $('.content').addClass('active')
    $("#spinner").css("display", "block");
    $('.no-pdf-cell').addClass('none');
    $('.content.active > .container-panel').addClass('transition visible')


    this.html = (document.getElementById("DATA-PDF").innerHTML);
    this.html = this.html.replace('styled accordion', '')
    this.usuarioServices.generarPDF(this.html, 'EMYR : ' + this.type, "Fecuencia : " + this.frequency).subscribe(data => {
      this.accionDescargar.emit(false);
      $('.dropdown.icon').show();
      $("#spinner").css("display", "none");
      $('.no-pdf-cell').removeClass('none');
      $('.title').removeClass('active')
      $('.content').removeClass('active')
      this.mensaje('PDF listo!', '');
      this.downloadFile(data.body, 'Mantenimiento de' +this.type + '.pdf')
    });


  }
  downloadFile(blob, fileName) {
    const link = document.createElement('a');
    this.blobToBase64(blob, (base64) => {
      link.href = base64;
      link.download = fileName;
      link.click();
      window.close()

    });
  };

  blobToBase64(blob, callback) {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64 = reader.result;
      callback(base64);
    };
    reader.readAsDataURL(blob);

  };


  mensaje(message, action) {
    this.snackBar.open(message, action, {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['gray-snackbar'],
    });
  }

}


