import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../core/guards/auth.guard';
import { DetalleComponent } from './pages/detalle/detalle.component';
import { AsigancionesComponent } from './pages/asiganciones/asiganciones.component';
import { RolGuard } from '../../core/guards/rol.guard';


const routes: Routes = [
  { path: 'detalle/:protocol/:ot/:code', component: DetalleComponent, canActivate: [AuthGuard] },
  { path: 'asignaciones', component: AsigancionesComponent, canActivate: [AuthGuard, RolGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministracionRoutingModule { }
