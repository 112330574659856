import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";


@Injectable({
    providedIn: 'root'
})
export class ApiServerService {
    //Este servicio se es para las llamadas a nuestro back
    headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('tokenApi')}`
    });
    headersFile = new HttpHeaders({
        //'Content-Type': 'undefined',
        'Authorization': `Bearer ${localStorage.getItem('tokenApi')}`
    });
    constructor(private http: HttpClient) {
    }

   

    newComentario(data: any) {
        return this.http.post(`${environment.urlBack}/detail/comments`, data, { headers: this.headers }).pipe(
            map((isAlive: any) => {
                return isAlive;
            })
        );
    }
    getComentario(id: any) {
        return this.http.get(`${environment.urlBack}/detail/comments/${id}`, { headers: this.headers }).pipe(
            map((isAlive: any) => {
                return isAlive;
            })
        );
    }
    getComentarios(nro: any) {
        return this.http.get(`${environment.urlBack}/detail/comments/ot/${nro}`, { headers: this.headers }).pipe(
            map((isAlive: any) => {
                return isAlive;
            })
        );
    }
    getDetail(data: any) {
        return this.http.post(`${environment.urlBack}/detail?token=${localStorage.getItem('token')}`, data, { headers: this.headers }).pipe(
            map((isAlive: any) => {
                return isAlive;
            })
        );
    }
   
}
