import { environment } from "src/environments/environment";

export class FiltroAsignaciones {
    NPerPage: number = 15;
    pageNumber: any = 1;
    pageTotal: any = 10;
    totalItems: any = 0;
    orderBy: string = "-1";
    state: string[];
    username: string;
    startDate: string;
    endDate: string;
    protocolName= environment.protocolName;
    description: string;
    assignedTo: string;
    ot: string;
    frencuency: string;
    sections: string[];
    sectionsCods: string[];

}