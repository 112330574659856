import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UsuarioService } from '../usuario/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class Service {
  //Este servicio es para las llamadas directamente al servidor de TGN
  token: string;
  constructor(private http: HttpClient, private usuarioServices: UsuarioService) {
    this.token = this.usuarioServices.getToken();
  }
  //nuevo
  getCargaInicial(protocolo, ot, code) {
    var query = '';
    query = query.concat(`api/results?orderBy={"When":-1}&q={"Activity_Id":"Carga Inicial","Status.CodigoEstacion.Observed":"${code}","Protocol_Id":"${protocolo}","Status.OT.Observed":"${ot}"}`)
    return this.http.get<any>(`${environment.apiUrl}/${query}&token=${this.token}`).pipe(
      map((res: any) => {
        return res.data[0];
      })
    );
  }
  getMissionId(protocolo, mission) {
    var query = '';
    query = query.concat(`api/results?orderBy={"When":-1}&q={"Mission_Id":"${mission}","Protocol_Id":{"$regex":"${protocolo}"}}`)
    return this.http.get<any>(`${environment.apiUrl}/${query}&token=${this.token}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }
  getActivities(missionId) {
    var query = '';
    query = query.concat(`api/results?orderBy={"When":-1}&q={"Mission_Id":"${missionId}"}`)

    return this.http.get<any>(`${environment.apiUrl}/${query}&token=${this.token}`).pipe(
      map((res: any) => {
        return res;
      })
    );


  }




  missionFinished(missionId) {
    return this.http.get<any>(`${environment.apiUrl}/api/results/update-mission-state?id=${missionId}`).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
}
