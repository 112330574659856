export class FiltroActividades{
    numberOT:number;
    denomination:number;
    dateFrom:Date;
    dateUntil:Date;
    emyr:string;
    section:string;
    frequency:string;
    state:String;
    orderWhen:String="down";
}