import {NgModule} from '@angular/core';
import {TitleComponent} from './title/title.component';
import {MatButtonModule} from '@angular/material/button';
import {MatChipsModule} from '@angular/material/chips';
import {MatDialogModule} from '@angular/material/dialog';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatToolbarModule} from '@angular/material/toolbar';
import {CommonModule} from '@angular/common';
import {ShellModule} from '../../modules/shell/shell.module';
import {MatTableModule} from '@angular/material/table';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { HeaderComponentComponent } from './header-component/header-component.component';
import { GalleryModalComponent } from './gallery-modal/gallery-modal.component';
import {IvyGalleryModule} from 'angular-gallery';
import { FormsModule } from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { ModelComentarioComponent } from './model-comentario/model-comentario.component';
import { SpinnerOverlayComponent } from './spinner-overlay/spinner-overlay.component';
import { RangeComponent } from './range/range.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { Paginator2Component } from './paginator2/paginator2.component';

@NgModule({
  declarations:[TitleComponent, HeaderComponentComponent, GalleryModalComponent, ModelComentarioComponent, PaginatorComponent,SpinnerOverlayComponent, RangeComponent, ConfirmDialogComponent, Paginator2Component],
  imports:[NgxSliderModule,MatProgressSpinnerModule,IvyGalleryModule,CommonModule, ShellModule, MatToolbarModule, MatProgressBarModule, MatButtonModule, MatGridListModule,
    MatDialogModule, MatIconModule, MatChipsModule, MatTableModule,FormsModule,BrowserModule,ReactiveFormsModule],
  providers:[],
  exports:[ConfirmDialogComponent,SpinnerOverlayComponent,RangeComponent,ModelComentarioComponent,HeaderComponentComponent, 
  GalleryModalComponent,PaginatorComponent,Paginator2Component],
  entryComponents:[]
})
export class SharedModule {
}
