<div class="container-responsive">
    <span>
        Área de separador y filtro
    </span>
    <span>
        Área de medición
    </span>
    <span>
        Área de regulación
    </span>
    <span>
        Calentadores
    </span>
    <span>
        Calentadores
    </span>
    <span>
        Instalaciones generales
    </span>
    <span>
        Tareas previas generales
    </span>
    <span>
        Calidad de gas
    </span>
</div>