<table class="ui basic table">
    <thead>
        <tr>
            <!--<th></th>-->

            <th (mouseenter)="toggleFilter('section')" (mouseleave)="toggleFilter('section')"> Sección
                <i class="filter icon icon-sm" ></i>
                <div class="popup" *ngIf="activeFilter === 'section'">
                    <div class="ui fluid left input">
                        <input [(ngModel)]="input.sections" placeholder="Filtrar por seccion" type="text">
                        <button [disabled]="!input.sections || input.sections?.trim()==''" class="plus"
                            (click)="addFilter('sections')"> <i class="add icon" [style.font-size]="'13px'"></i> </button>
                    </div>
                   <div [style.margin]="'10px'">
                    <span class="value-p" *ngFor="let v of filtros.sections">
                        {{v}} <span class="clear-x"  (click)="removeFilter('sections',v)"> x </span>

                    </span>
                   </div>
                    <div class="btn-container">
                    
                        <!--<button (click)="cargarLista()" class="btn-white">FILTRAR</button>-->
                        <button (click)="limpiar('sections')" class="btn-white">BORRAR TODO</button>
                    </div>
                </div>
            </th>
            <th> Cod. Sección </th>

            <th (mouseenter)="toggleFilter('ot')" (mouseleave)="toggleFilter('ot')"> OT
                <i class="filter icon icon-sm" ></i>
                <div class="popup" *ngIf="activeFilter === 'ot'">
                    <div class="ui fluid left input">
                        <input [(ngModel)]="filtros.ot"   placeholder="Filtrar por OT" type="text">
                    </div>

                    <div class="btn-container">
                        <button (click)="cargarLista()"  class="btn-white">FILTRAR</button>
                        <button (click)="limpiar('ot')" class="btn-white">BORRAR</button>
                    </div>
                </div>
            </th>
            <th (mouseenter)="toggleFilter('denominacion')" (mouseleave)="toggleFilter('denominacion')"> Denominación
                <i class="filter icon icon-sm" ></i>
                <div class="popup" *ngIf="activeFilter === 'denominacion'">
                    <div class="ui fluid left input">
                        <input [(ngModel)]="filtros.description" placeholder="Filtrar por denominacion"
                            type="text">
                    </div>

                    <div class="btn-container">
                        <button (click)="cargarLista()"  class="btn-white">FILTRAR</button>
                        <button (click)="limpiar('description')" class="btn-white">BORRAR</button>
                    </div>
                </div>
            </th>
            <th (mouseenter)="toggleFilter('fecha')" (mouseleave)="toggleFilter('fecha')"> Fecha y Hora
                <i class="filter icon icon-sm" ></i>
                <div class="popup" *ngIf="activeFilter === 'fecha'">
                    <div class="ui input filter-date" color="accent" appearance="outline">
                        <span class="label">
                            Desde :
                        </span> <input  [(ngModel)]="filtros.startDate" type='date' placeholder="Desde">
                    </div>
                    <div class="ui input filter-date" color="accent" appearance="outline">
                        <span class="label">
                            Hasta :
                        </span>
                        <input  [(ngModel)]="filtros.endDate"   type='date' placeholder="Hasta">
                    </div>
                    <div class="btn-container">
                        <button (click)="cargarLista()"  class="btn-white">FILTRAR</button>
                        <button (click)="limpiar('date')" class="btn-white">BORRAR</button>
                    </div>
                </div>
            </th>
            <th style="position: relative;" (mouseenter)="toggleFilter('frecuencia')" (mouseleave)="toggleFilter('frecuencia')"> Frecuencia
                <i class="filter icon icon-sm"></i>
                <div class="popup" *ngIf="activeFilter === 'frecuencia'">
                    <div class="ui input" style="margin-bottom: 5%;">
                        <select [(ngModel)]="filtros.frencuency"   class="ui selection dropdown">
                            <option value="" disabled selected hidden>Seleccione:Frecuencia</option>
                            <option value="Mensual">Mensual</option>
                        </select>
                    </div>

                    <br>

                   
                    <div class="btn-container">
                        <button (click)="cargarLista()" class="btn-white">FILTRAR</button>
                        <button (click)="limpiar('frencuency')" class="btn-white">BORRAR</button>
                    </div>
                </div>
            </th>
            <th style="position: relative;" (mouseenter)="toggleFilter('user_category')" (mouseleave)="toggleFilter('user_category')"> Asignado a
                <i class="filter icon icon-sm" ></i>
                <div class="popup" *ngIf="activeFilter === 'user_category'">
                    

                    <div class="ui fluid left input">
                        <input [(ngModel)]="filtros.assignedTo"  placeholder="Buscar" type="text">
                    </div>
                    <div class="btn-container">
                        <button (click)="cargarLista()"  class="btn-white">FILTRAR</button>
                        <button (click)="limpiar('assignedTo')" class="btn-white">BORRAR</button>
                    </div>
                </div>
            </th>
            <th> </th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let item of assigned; index as i ">
            <tr>
                <!--<td>
                    <i class="caret right icon" *ngIf="selected2!=i" (click)="selected2= i"></i>
                    <i class="caret down icon" *ngIf="selected2==i" (click)="selected2=null"></i>

                </td>-->

                <td> {{item.Status.Seccion?.Observed}}</td>
                <td> {{item.Status.CodigoSeccion?.Observed}}</td>
                <td> {{item.Status.OT?.Observed}}</td>
                <td> {{item.Status.Description?.Observed}}</td>
                <td>               {{item.When | date: 'dd/MM/yyyy' }}
                </td>            
                <td> {{item.Status.Frecuencia?.Observed}}</td>
                <td> {{item.Who[0]?.username}}
                </td>
                <td>
                    <span class="state green" *ngIf="item.State == 'downloaded'">
                        DESCARGADA
                    </span>
                    <span class="state yellow" *ngIf="item.State == 'assigned'">
                        ASIGNADA
                    </span>
                </td>
                <td style="position: relative;">
                    <button   class="edit-btn">
                        <i class="ellipsis vertical icon  " (click)="toggleEdit(i)"></i>
                    </button>
                    <div *ngIf="activeEdit === i" class="popup pop-right" >

                        <button class="ui right labeled icon button button-filter primary" *ngIf="item.State == 'assigned' "
                            (click)="desasignar(item._id)" [disabled]="!puedeCambiar(item)" [class.no-pointer]="!puedeCambiar(item)">

                            Desasignar
                        </button>
                        <button class="ui right labeled icon button button-filter primary" *ngIf="item.State == 'downloaded' "
                            (click)="asignar(item._id)" [disabled]="!puedeCambiar(item)" [class.no-pointer]="!puedeCambiar(item)">

                            Asignar
                        </button>
                    </div>
                   
                </td>
            </tr>
            <!--<tr *ngIf="selected2==i">
                <td colspan="1"></td>
                <td colspan="6">
                    <app-asingaciones-detalles></app-asingaciones-detalles>
                </td>
            </tr>-->
        </ng-container>


    </tbody>
</table>
<div class="ui grid" *ngIf='filtros.pageTotal >0 '>
    <div class="column centered row paginacion">
        <app-paginator2 #paginacion [size_page]="filtros.pageNumber" [totalElements]="filtros.totalItems" [pages_total]='filtros.pageTotal'
            (eventEmmiterChangePage)='changePageTable2($event)'>
        </app-paginator2>
    </div>
</div>