<div class="ui container">
    <app-header-component *ngIf="frequency && code" [frequency]="frequency" [type]='code'>
    </app-header-component>
    <div id="DATA-PDF">
        <div style='overflow-x: auto;' style="margin-bottom: 2em;" *ngIf="ot">
            <table class="ui celled structured table unstackable">
                <thead>
                    <tr>
                        <th colspan="2" class="center aligned">DATOS PRELIMINARES</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class='label'>CÓDIGO:<span class='negrita'>{{code}}</span></td>
                        <td class='label'>Denominación:<span class='negrita'>{{ut}}</span></td>


                    </tr>
                    <tr>
                        <td class='label '> OT:<span class='negrita'>{{ot}}</span></td>
                        <td class='label'>Sección:<span class='negrita'>{{section }}</span></td>

                    </tr>
                    <tr>
                        <td class='label'> Fecha:<span class='negrita'>{{when | date: 'dd/MM/yyyy' }}</span></td>
                        <td class='label'>Ejecutante:<span class='negrita'>{{performer}}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="ui divider "></div>


        <div *ngFor="let item of actividades;index as i" class="container-protocol ">
            <div class="ui grid " style="margin-top: 0.5em;">

                <div class="sixteen wide column">
                    <h4 class="title-style">{{item.title}}</h4>

                    <div *ngFor="let instruccion of item.items;index as indexSeccion" class="ui styled accordion " style="margin-top: 0.5em;">
                        <div [id]="'panel'+item.title+indexSeccion" class="title " (click)="activePanel(item.title+indexSeccion)">
                            <i class="dropdown icon"></i>
                            {{instruccion.name}}
                            <i *ngIf="instruccion.avisos" class=" exclamation triangle icon pointer" [style.color]="'red'">
                            </i>
                        </div>
                        <div [id]="'content'+item.title+indexSeccion" class="content ">
                            <app-table-results *ngIf="item" [nro]="ot" [data]="[instruccion]"></app-table-results>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>

    <div class="ui grid">
        <div class="extra content">
            <button style="margin-top: 3em;" class="ui right labeled icon button button-filter primary"
                (click)="goHome()">
                <i class="home
                          icon"></i>
                Volver
            </button>
        </div>
    </div>
</div>