<div class="ui container-home">
  <div class="ui grid">
    <div class="four  wide column  ">
      <div class="ui card filter" id='card-filter' style='background-color:rgb(249, 249, 249);'>
        <div class="content">
          <div class=" ui left floated header">Filtros
            <i class="filter icon light-blue"></i>
          </div>

        </div>
        <div class="content">
          <form [formGroup]="formDatos" id='form' class="ui form">
            <div class="field ">
              <label class='label light-blue'>N° OT </label>
              <div class="ui input">
                <select class="ui selection dropdown" formControlName="numberOT">

                  <option value="">Seleccione:N° OT</option>
                  <option *ngFor="let n of otFilters" [value]="n">{{n}}
                  </option>
                </select>
              </div>

            </div>
            <div class="field">
              <label class='label light-blue'>Denominación UT</label>
              <div class="ui input">
                <select class="ui selection dropdown" formControlName="denomination">
                  <option value="">Seleccione:Denom. UT</option>
                  <option *ngFor="let n of utFilters" [value]="n">{{n}}
                  </option>
                </select>
              </div>
            </div>


            <!--<div class="field">
              <label class='label light-blue'>Sección</label>
              <div class="ui input">
                <select class="ui selection dropdown" formControlName="section">
                  <option value="">Seleccione:Sección</option>
                  <option *ngFor="let n of sectionFilters" [value]="n">{{n}}
                  </option>
                </select>
              </div>
            </div>-->
            <div class="field">
              <label class='label light-blue'>Código EMyR</label>
              <div class="ui input">
                <select class="ui selection dropdown" formControlName="emyr">
                  <option value="">Seleccione:Código EMyR</option>
                  <option *ngFor="let n of typeFilters" [value]="n">{{n}}
                  </option>
                </select>
              </div>
            </div>
            <div class="field">
              <label class='label light-blue'>Estado</label>
              <div class="ui input">
                <select class="ui selection dropdown secciones" formControlName="state">
                  <option value="">Seleccione:Estado</option>
                  <option *ngFor='let n of stateFilters' [value]="n">{{n}}</option>
                </select>
              </div>
            </div>

            <div class="field">
              <label class='label light-blue'>Fecha Desde</label>
              <div class="ui input" color="accent" appearance="outline">
                <input formControlName="dateFrom" type='date' placeholder="29/03/2017">
              </div>

            </div>
            <div class="field">
              <label class='label light-blue'>Fecha Hasta</label>

              <div class="ui input" color="accent" appearance="outline">
                <input formControlName="dateUntil" type='date' placeholder="29/03/2017">
              </div>
            </div>
            <div class="field ">
              <label class='label light-blue'>Frecuencia</label>
              <div class="ui input">
                <select class="ui selection dropdown" formControlName="frequency">
                  <option value="">Seleccione:Frecuencia</option>
                  <option *ngFor="let n of  frequencyFilters" [value]="n">{{n}}
                  </option>
                </select>
              </div>

            </div>
          </form>
        </div>
        <div class="extra content">
          <button class="ui right labeled icon button button-filter primary" [disabled]='formDatos.invalid'
            (click)="aplicar()">
            <i class=" check icon"></i>
            APLICAR
          </button>
          <button class="ui right labeled icon button button-filter primary" (click)="limpiar()">
            <i class="trash alternate
                        icon"></i>
            LIMPIAR
          </button>
        </div>
      </div>
    </div>
    <div class="twelve wide column " style="justify-content:center !important;">
      <div class="ui warning message" *ngIf='listRegistros.length === 0 && !spinner  '>
        <i class="close icon"></i>
        <div class="header">
          No se encontraron registros
        </div>
      </div>
      <table style='margin:auto;' class="ui celled selectable table" *ngIf=' listRegistros.length> 0'>
        <thead>
          <tr>
            <th class="center aligned">N° OT</th>
            <th class="center aligned">Denominación de UT</th>
            <th class="center aligned">Código EMyR</th>
            <th class="center aligned">Estado</th>
            <th class="center aligned">Fecha  
              <i (click)="changeOrder('down')" *ngIf="filtros.orderWhen!='down'" class="caret down icon pointer"></i>
              <i (click)="changeOrder('up')"*ngIf="filtros.orderWhen!='up'"class="caret up icon pointer"></i>
            </th>
            <th class="center aligned">Frecuencia</th>
            <th class="center aligned">Avisos</th>
            <th class="center aligned"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor='let item of listRegistros;index as index ;'>
            <td class="center aligned">
              {{item.Status.OT.Observed}}
            </td>
            <td class="center aligned">
              {{item.Status.Description.Observed}}
            </td>

            <td class="center aligned">
              {{item.Status.CodigoEstacion?.Observed}}
            </td>
            <td class="center aligned">
              <p>
                {{item.state}}
              </p>
            </td>
            <td class="center aligned">
              {{item.When | date: 'dd/MM/yyyy' }}
            </td>
            <td class="center aligned">
              {{item.Status.Frecuencia.Observed }}
            </td>
            <td class="center aligned">
              <p>{{item.cantidadDeAvisoDeMantenimiento}}</p>
            </td>
            <td class="center aligned">
              <div class="ui vertical animated button" tabindex="0" (click)="detalle(item)">
                <div class="hidden content">Ver </div>
                <div class="visible content">
                  <i class="arrow right icon"></i>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="ui grid" *ngIf='pages_total >0 '>
        <div class="column centered row paginacion">
          <app-paginator #paginacion [pages_total]='pages_total' (eventEmmiterChangePage)='changePage($event)'>
          </app-paginator>
        </div>
      </div>
    </div>
  </div>
</div>