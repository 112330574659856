import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('tokenApi')}`
  });
  constructor(private http: HttpClient) {
  }

  generarPDF(html: string, title: string, state: string) {
    let watermark = true;
    if (state == "Finalizado") {
      watermark = false
    }
    let datos = {
      "html": `${html}`,
      "type": title,
      "state": `${state}`,
      "watermark": `${watermark}`
    }

    return this.http.post(environment.urlBack + '/pdf/get', datos, { reportProgress: true, responseType: 'blob' as 'blob', observe: 'response', headers:this.headers  }).pipe(tap(
      (res: any) => {
        return res;
      }));

  }


  setData(data: any) {
    localStorage.setItem('username', data.userName)
    localStorage.setItem('roles', JSON.stringify(data.userInfoDTOs))
  }

  getUsername() {
    return localStorage.getItem('username');
  }
  setUserCategories(data: any) {
    localStorage.setItem('userCategories', JSON.stringify(data.user.categories))
  }

  getUserCategories() {
    return localStorage.getItem('userCategories');
  }
  setToken(token: string) {
    localStorage.setItem('token', token)
  }

  setTokenApi(token: string) {
    localStorage.setItem('tokenApi', token)
  }
  isLoggedIn() {
    return  localStorage.getItem('tokenApi');
  }

  clear() {
    localStorage.clear();
  }

  getRoles() {
    let info = JSON.parse(localStorage.getItem('roles'))
    let array = [];
    if(info){
      info.forEach(i => {
        array.push(i.puesto)
      })
    }
   
    return array;
  }
  getSections() {
    console.log(localStorage.getItem('roles'))
    let info = JSON.parse(localStorage.getItem('roles'))
    let array = [];

    if(info){
      info.forEach(i => {
        array.push(i.seccion)
      })
    }
   
    return array;
  }
  getToken() {
    return localStorage.getItem('token');
  }
  getState(){
    const roles = this.getRoles();
    if (roles &&  (!roles.includes('Ejecutor') || !roles.includes('Jefe de Sección') && !roles.includes('Analista de Mantenimiento'))
    ) {
      return false;
    }
    return true
  }
}







