import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UsuarioService } from '../services/usuario/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class RolGuard implements CanActivate {

  constructor(private usuarioService: UsuarioService, private router: Router) {}

  canActivate(): boolean {
    const roles = this.usuarioService.getRoles();
    if (roles &&  (!roles.includes('Ejecutor') && !roles.includes('Jefe de Sección') && !roles.includes('Analista de Mantenimiento'))){
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
