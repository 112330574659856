<div>
    <i mat-dialog-close class="close icon"></i>
    <span class="cant-comentarios">Seleccione una opción </span>
    <mat-dialog-content style="margin-top:1em;min-width: 30vw;" class="mat-typography">
        <div class="ui input" style="width: 100%;">
            <select [disabled]="esEjecutor" [(ngModel)]="userSelected" class="ui selection dropdown secciones" >
              <option *ngFor='let n of users' [value]="n">{{n}}</option>
            </select>
          </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button (click)="asignar()"  class="ui  button  primary">
            Guardar
        </button>
    </mat-dialog-actions>

</div>