import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AsignacionesService } from 'src/app/core/services/asignaciones/asignaciones.service';
import { UsuarioService } from 'src/app/core/services/usuario/usuario.service';
import { DataService } from 'src/app/data.service';
import { FiltroAsignaciones } from 'src/app/shared/models/filtrosAsignaciones';
import { AsignarComponent } from '../asignar/asignar.component';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-tabla-asignadas',
  templateUrl: './tabla-asignadas.component.html',
  styleUrls: ['./tabla-asignadas.component.scss']
})
export class TablaAsignadasComponent implements OnInit {
  filtros = new FiltroAsignaciones();
  assigned: any[] = []
  activeFilter: string = '';
  activeEdit: number | null = null;
  secciones: any
  rol: any;
  input: any = {}
  constructor(public snackBar: MatSnackBar,
    private dataService: DataService,
    private asignacionService: AsignacionesService,
    public dialog: MatDialog,
    private usuarioService: UsuarioService
  ) {
    this.secciones = this.usuarioService.getSections();
    this.rol = this.usuarioService.getRoles()

    if (this.secciones[0] != '*' && this.rol.includes("Ejecutor")) {
      this.secciones.forEach(d => {
        this.input.sectionsCods = d;
        this.addFilter("sectionsCods")
      })
    }
  }

  ngOnInit(): void {
    this.changePageTable2(1);

  }
  pagesTotalTable2 = 1;
  changePageTable2(page: number) {
    this.filtros.pageNumber = page;
    this.cargarLista();
  }
  puedeCambiar(item) {
    if (this.rol == 'Ejecutor' && item.Who[0].username == this.usuarioService.getUsername()) {
      return true;
    }
    if (this.rol == 'Jefe de Sección' || this.rol == "Analista de Mantenimiento") {
      if (this.secciones.includes(item.Status.CodigoSeccion?.Observed) || this.secciones[0] == '*') {
        return true;
      }
    } else {
      return false;
    }
    return false;
  }
  cargarLista() {
    this.filtros.state = ['assigned', 'downloaded'];

    this.filtros.protocolName = environment.protocolName;

    this.asignacionService.filtrar(this.filtros).subscribe(res => {
      this.assigned = res.data.map(item => {
        item.When = new Date(item.When)
        return item;
      });
      this.filtros.totalItems = res.count;
      this.filtros.pageTotal = res.count / this.filtros.NPerPage;
      console.log(this.filtros)
      $('#spinner-load').css("display", "none");
      console.log("assigned", this.assigned)
    })

  }
  filterText(tipo, event) {
    const inputValue = (event.target as HTMLInputElement).value;
    this.filtros[tipo] = inputValue;
  }
  filterDate(tipo, event) {
    const inputValue = (event.target as HTMLInputElement).value;
    const inputDate = new Date(inputValue);
    const inputDateInMilliseconds = inputDate.getTime();
    this.filtros[tipo] = inputDateInMilliseconds;
  }
  filterSelect(tipo, event) {
    const inputValue = (event.target as HTMLInputElement).value;
    this.filtros[tipo] = inputValue;
  }
  asignar(id) {
    const dialogRef = this.dialog.open(AsignarComponent, { panelClass: 'custom-dialog-container', data: { itemsSelecteds: id } });
    dialogRef.afterClosed().subscribe(() => {
      //this.tablaNoAsignadasComponent.cargarLista();
    });
  }

  desasignar(id) {

    this.asignacionService.desasignar(id).subscribe(
      res => {
        this.snackBar.open('Desasignado correctamente', 'x', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: ['green-snackbar'],
        });
        this.cargarLista()
      },
      (err) => {
        let errorMessage = 'No se pudo desasingar : ' + err.error.message;

        this.snackBar.open(errorMessage, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: ['red-snackbar'],
        });
      }
    );
  }

  toggleEdit(index: number) {
    this.activeEdit = this.activeEdit === index ? null : index;
  }
  toggleFilter(filterName: string) {
    this.activeFilter = this.activeFilter === filterName ? '' : filterName;
  }
  limpiar(tipo) {
    if (tipo == "date") {
      this.filtros['startDate'] = null;
      this.filtros['endDate'] = null;
    } else {
      this.filtros[tipo] = null;
    }
    this.cargarLista();
  }
  addFilter(tipo) {
    if (this.filtros[tipo]) {
      this.filtros[tipo].push(this.input[tipo])
    } else {
      this.filtros[tipo] = [this.input[tipo]]
    }
    this.input[tipo] = '';
    this.cargarLista();
  }
  removeFilter(tipo, value) {
    this.filtros[tipo] = this.filtros[tipo].filter(d => d != value);
    this.cargarLista();

  }
}
